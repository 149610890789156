.App {
  text-align: center;
  background-color: #f7eee2;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  overflow: scroll;
  padding-top: 2rem;
}
body {
  margin: 0;
}

.logo{
  width: 200px;
}
.App-logo {
  width: 600px;
  max-width: 90vw;
  pointer-events: none;
  position: relative;
  bottom: 0;
  animation: MoveUpDown 1s linear infinite;
  margin: 2rem 0;

}



@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 2px;
  }
}

h1{
  font-family: 'Universal', serif;
  color: #e42627;
  letter-spacing: 3px;
}



.social{
  width: 50px;
  height: 50px;
}

@font-face {
  font-family: 'Universal';
  src: local('Universal'), url(./Assets/Universal\ Serif.ttf) format('truetype');
}